//* colors
$blue-color: #109cf1;
$yellow-color: #ffb946;
$red-color: #f7685b;
$green-color: #2ed47a;
$purple-color: #885af8;
$text-color: #192a3e;
$light-color: #fff;
$table-color: #323c47;
$grey-color: #707683;
$dark-blue-color: #334d6e;
$darkk-blue-color: #122845;
$light-grey-color: #90a0b7;
$icon-color: #c2cfe0;
$border-color: #ebeff2;
$white-color: #fff;
//* font
$main-font: "Poppins", sans-serif;
