@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.thhh {
  font-size: 14px !important;
  color: $blue-color;

  border: none !important;
  min-width: 16%;
  width: 16%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    min-width: 4%;
    width: 4%;
  }
}
.thhhs {
  font-size: 14px !important;
  color: $blue-color;

  border: none !important;
  min-width: 19%;
  width: 19%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    min-width: 4%;
    width: 4%;
  }
}
.thead {
  min-height: 58px !important;
}

.tddd {
  font-size: 12px !important;
  color: $grey-color;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  min-width: 16% !important;
  width: 16% !important;

  &:first-child {
    min-width: 4% !important;
    width: 4% !important;
  }
}
.tddds {
  font-size: 12px !important;
  color: $grey-color;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  min-width: 19% !important;
  width: 19% !important;

  &:first-child {
    min-width: 4% !important;
    width: 4% !important;
  }
}

.btnnn {
  width: 100% !important;
  margin-right: 5px;
  padding: 5px 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px !important;
  border-radius: 12px !important;
}

.outt {
  width: 100% !important;
  min-width: auto !important;
  border-radius: 12px !important;
}

.outt-tt {
  margin: 0;
  font-size: 12px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@include breakpoint-down(780px) {
  .btnnn > span {
    display: none;
  }
}
