@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.landing-container {
  max-width: 1240px;
  width: 100%;
  padding: 0 20px !important;
  margin: 0 auto !important;
}

.card-open {
  padding: 80px 0;
  margin-top: 85px;
}

.card-open__wrapper {
  width: 100%;
  background-color: #fff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
}

.card-head,
.card-head__img {
  width: 100%;
  height: 410px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-wrapper__body {
  padding: 40px 50px;
}

.card-detail__info {
  width: 100%;
}

.card-body__list {
  @include list-unstyled;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.card-body__item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.card-body__item-img {
  margin-right: 12px;
}

.card-body__title {
  width: 100%;
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  color: $blue-color;
  text-transform: uppercase;
  text-align: center;
}

.card-body__text {
  margin-bottom: 0;
  color: $grey-color;
  font-size: 15px;
  white-space: pre-wrap;
}

.card-body__item-tt {
  color: $grey-color;
  font-size: 13px;
}

@include breakpoint-down(780px) {
  .card-open {
    padding: 50px 0;
  }

  .card-body__title {
    font-size: 20px;
    line-height: 25px;
  }

  .card-body__text,
  .card-body__item-tt {
    font-size: 12px;
  }
}

@include breakpoint-down(560px) {
  .card-open {
    padding: 30px 0;
  }

  .card-body {
    padding: 12px;
  }

  .card-head,
  .card-head__img {
    height: 250px;
  }

  .card-body__title {
    margin-bottom: 12px;
    font-size: 16px;
  }

  .card-body__list {
    margin-bottom: 12px;
  }

  .card-body__item {
    margin-right: 8px;
  }

  .card-body__item-img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  .card-body__item-tt {
    font-size: 11px;
  }
}

@include breakpoint-down(390px) {
  .card-head,
  .card-head__img {
    height: 210px;
  }

  .card-body__title {
    font-size: 14px;
  }
}
