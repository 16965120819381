@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.sidebar {
  position: sticky;
  top: 100px;
  left: 0;
  width: 100%;
  border-radius: 15px;
}

.sidebar-top {
  background-color: #fff;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  border-radius: 15px;
}

.sidebar__head {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
}

.head-sidebar {
  border-bottom: 1px solid $border-color;
  padding: 24px 18px;
  transition: all ease 0.4s;

  &:hover {
    background-color: #f6f6f6;
    border-radius: 15px 15px 0 0;
  }
}

.head-sidebar__title {
  font-weight: 400;
  color: $blue-color;
  font-size: 18px;
  line-height: 25px;
  text-decoration: none;
}

.sidebar-entrance {
  padding: 24px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;
}

.sidebar-entrance__logo {
  margin-right: 16px;
}

.sidebar-entrance__img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.sidebar-entrance__detail-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.sidebar-entrance__detail-text {
  // width: 100px;
  margin-right: 10px;
  font-weight: 400;
  display: block;
  font-size: 11px;
  line-height: 16px;
  color: $light-grey-color;
}

.sidebar-main {
  padding: 6px;
}

.tick-icon{
  font-size: 18px;
  color: $green-color
}

.x-icon{
  font-size: 18px;
  color: $yellow-color
}

.x-danger{
  color: $red-color
}

.sidebar-list {
  @include list-unstyled;
  border-radius: 15px;
  background-color: #fff;
}

.sidebar-list__item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  transition: all ease 0.4s;
  background-color: #fff;
  border-radius: 12px;

  &:hover {
    background-color: #f6f6f6;
  }

  &:nth-child(4) {
    margin-bottom: 0 !important;
  }

  &:last-child:hover {
    background-color: #f6f6f6;
  }
}

.sidebar-entrance {
  margin-bottom: 5px;
  transition: all ease 0.4s;
  border-radius: 12px;

  &:hover {
    background-color: #f6f6f6;
  }
}

.sidebar-list__texttt {
  font-size: 14px;
  font-family: $main-font;
  line-height: 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

.sidebar-list__item:hover .sidebar-list__link,
.sidebar-list__item:hover .sidebar-list__icon {
  text-decoration: none;
  color: $blue-color;
}

.sidebar-list__item > .sidebar-list__link.active .sidebar-list__icon {
  color: $blue-color;
}

.sidebar-list__item > .sidebar-list__link.active {
  background-color: #f6f6f6;
}

.sidebar-list__link.active:last-child {
  border-radius: 12px;
}

.sidebar-list__link {
  padding: 18px 0;
  display: flex;
  align-items: center;
  width: 100%;
  color: $text-color;
  text-decoration: none;
}

.ddd {
  display: block;
  border-top: 1px solid #e9e9e9;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0;
  cursor: pointer;
}

.head-sidebar__title-open {
  display: none;
  text-align: center;
  color: $blue-color;
  font-size: 18px;
  align-items: center;
}

.sidebar-list__icon {
  align-items: center;
  padding-left: 24px;
  margin-right: 16px;
  font-size: 16px;
  color: $icon-color;
}

.sidebar-list__text_head {
  font-size: 1;
}

.bild {
  display: none;
}

@include breakpoint-down(998px) {
  .bild {
    display: block;
  }

  .head-sidebar {
    display: none;
  }

  .cursor-logout {
    cursor: pointer;
    &:hover {
      color: $blue-color;
    }
  }

  .head-sidebar,
  .sidebar-entrance,
  .sidebar-list__text,
  .sidebar-main__entrance {
    display: none !important;
  }

  .sidebar-list__link.active .sidebar-list__item:last-child,
  .sidebar-list__link.active .sidebar-list__item:first-child {
    background-color: $blue-color;
  }

  .sidebar-list__item:last-child,
  .sidebar-list__item:first-child {
    border-radius: 12px;
    background-color: transparent;
  }

  .sidebar-list__item {
    width: 20%;
    margin-bottom: 0;
  }

  .sidebar-list__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-list__link.active {
    width: 100% !important;
    background-color: $blue-color !important;
    border-radius: 12px !important;
  }

  .sidebar-list__item > .sidebar-list__link.active .sidebar-list__icon {
    color: #fff !important;
  }

  .sidebar-list__icon {
    margin: 0;
    padding: 0;
    font-size: 30px;
  }

  .ddd {
    border-top: none;
    border-radius: 12px;
  }

  .sidebar-list__item,
  .ddd {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20%;
  }

  .sidebar-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }

  .sidebar-top {
    width: 100%;
    margin-bottom: 0;
  }
}

@include breakpoint-down(670px){
  .sidebar-list__link{
    padding: 12px 0;
  }

  .sidebar-list__icon{
    font-size: 20px;
  }
}
