@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.wrapper {
  padding: 40px 20px;
  margin: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/icon/hero-bgi-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.forms {
  padding: 25px;
  background-color: #fff;
  border: 2px solid $blue-color;
  border-radius: 20px;
  width: 800px;
}

.title {
  font-size: 25px;
  font-weight: 500;
  color: $blue-color;
  text-transform: capitalize;
  align-items: center;
}

.btnn {
  border: none;
  background-color: $blue-color;
  color: #fff;
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  transition: all ease 0.4s;

  &:hover {
    opacity: 0.8;
  }
}

.inpp {
  padding: 15px 10px !important;
  font-size: 12px !important;
  border: 2px solid #e8e8e8 !important;
  border-radius: 12px !important;

  &::placeholder {
    font-size: 12px;
    color: $light-grey-color;
  }

  &:focus {
    border: 2px solid $blue-color !important;
  }

  &.invalid {
    border: 1px solid red !important;
  }
}
.inpp-country {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border: 2px solid #e8e8e8 !important;
  border-radius: 12px !important;

  &::placeholder {
    font-size: 12px;
    color: $light-grey-color;
  }

  &:focus {
    border: 2px solid $blue-color !important;
  }

  &.invalid {
    border: 1px solid red !important;
  }
}
.inpp-select {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border: 2px solid #e8e8e8 !important;
  border-radius: 12px !important;
  width: 100%;

  &::placeholder {
    font-size: 12px;
    color: $light-grey-color;
  }

  &:focus {
    border: 2px solid $blue-color !important;
  }

  &.invalid {
    border: 1px solid red !important;
  }
  &:active {
    border: 2px solid #e8e8e8 !important;
  }
}
.form-check-input {
  margin-top: 0.5rem !important;
}

.lab {
  margin-bottom: 5px;
  color: $blue-color;
  font-size: 12px;
  font-weight: 600;
}

.css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  color: red !important;
}

.css-16ubnlw-MuiStepLabel-labelContainer {
  color: $light-grey-color !important;
}

.MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.css-qivjh0-MuiStepLabel-label {
  color: $blue-color !important;
}

@include breakpoint-down(878px) {
  .forms {
    width: 100%;
  }
}

.PhoneInputCountry {
  width: 30px !important;
  border: none !important;
}
.PhoneInputInput {
  outline: none;
  border: none;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: white;
}
.invalid-phone {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.err-phone {
  border: 1px solid red !important;
}
