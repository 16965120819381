@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.wrapper {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper-containers {
  height: 350px;
  background-color: #fff;
}

.wrapper__charts {
  width: 60%;
  margin-right: 20px;
}
.lines-beetwen {
  border: 0.1px solid #ebeff2;
  /* transform: rotate(-90deg); */
  /* width: 100%; */
  height: 100%;
  margin-left: 10px;
}
.wrapper__lines {
  width: 40%;
}

.wrapper-container__line {
  height: 80%;
  position: relative;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper-container__detail {
  width: 100%;
  padding: 10px 20px;
  height: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-container__chart {
  margin-right: 50px;
  width: 220px;
  height: 220px;
}

.detail-chart__holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.chart-lists {
  width: 40%;
  @include list-unstyled;
  border-top: 1px solid $border-color !important;
  border-bottom: 1px solid $border-color !important;
}

.chart__itemms {
  width: 100%;
  text-align: left;
  padding: 7px 0;
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border-color !important;
  border-left: 3px solid $border-color !important;

  &:last-child {
    border-bottom: none;
  }
}

.chart-item__num {
  font-size: 12px;
  font-weight: 600;
}

.item-gg {
  border-left: 3px solid $green-color !important;
}
.item-rrs {
  border-left: 3px solid $red-color !important;
}
.item-rrs .chart-item__tt::before {
  border: 2px solid $red-color !important;
}
.item-rrs .chart-item__num {
  color: $red-color !important;
}
.item-pp {
  border-left: 3px solid $purple-color !important;
}
.item-pp .chart-item__tt::before {
  border: 2px solid $purple-color !important;
}
.item-pp .chart-item__num {
  color: $purple-color !important;
}
.item-bb {
  border-left: 3px solid $blue-color !important;
}
.item-bb .chart-item__tt::before {
  border: 2px solid $blue-color !important;
}

.item-bb .chart-item__num {
  color: $blue-color !important;
}
.item-yy .chart-item__tt::before {
  border: 2px solid $yellow-color !important;
}

.item-gg .chart-item__num {
  color: $green-color !important;
}

.item-yy {
  border-left: 3px solid $yellow-color !important;
}

.item-yy .chart-item__num {
  color: $yellow-color !important;
}

.statistic {
  padding: 0 20px 25px 20px;
}

.chart-item__tt {
  min-width: 72%;
  font-size: 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;

  &::before {
    margin-right: 10px;
    border: 2px solid #2ed47a;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    content: "";
  }
}

.st__titles {
  min-height: 78px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #e6e6e6;
}
.wrapperdepshifr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.wrapperdepshifr__item {
  width: calc(50% - 10px);
}

@include breakpoint-down(980px) {
  .wrapperdepshifr {
    flex-direction: column;
  }
}
.wrapperdepshift__item {
  width: 100%;
}
