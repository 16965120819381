@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.header {
  background-color: #fff !important;
  border: 1px solid $border-color;
  height: 75px;
  display: flex;
  align-items: center;
}

.open-landing__head {
  color: $blue-color !important;
  font-size: 14px !important;
}

.sidebar-entrance__imgs {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100% !important;
}

.info-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-header__wrapper {
  display: inline-flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.info-header__text {
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  color: #4c5862;
}

.info-header__icon {
  margin-right: 8px;
  font-size: 12px;
  color: $blue-color;
}

.header__detail {
  display: flex;
  align-items: center;
}

.detail-header__dropdown-link {
  margin-right: 22px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header__detail-text {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: $text-color;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  &:after {
    margin-left: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    content: "";
  }
}

.header-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include list-unstyled;
}

.header-list__icon {
  font-size: 18px;
  color: $icon-color;
}

// nav style

.nav {
  display: flex;
  align-items: center;
}

.nav__item {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.nav__link {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  color: $grey-color;

  &:hover {
    text-decoration: none;
    color: $blue-color;
  }
}

.active {
  color: $blue-color;
}

.menu {
  width: 100px;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid $icon-color;
}
.menu-item {
  padding: 0px 20px;

  margin: 10px 0px;
  cursor: pointer;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  border-top: 3px solid $blue-color !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-1e9lk82-MuiListItemAvatar-root {
  min-width: 30px !important;
}

.css-10hburv-MuiTypography-root {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 8px 0px;
}
.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-colorPrimary {
  border-radius: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
}
.MuiButtonBase-root {
  border-radius: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
}
.lang {
  margin-right: 10px;
  width: 25px;
  height: 18px;
  object-fit: cover;
  // border-radius: 50%;
}

.sidebar-entrance__detail-title {
  margin: 0;
  margin-right: 15px;
  color: $blue-color;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.fll {
  width: 25px;
  height: 18px;
  object-fit: cover;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-137ul40-MuiButtonBase-root-MuiIconButton-root {
  margin: 0 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  border: none !important;
}

.menu-open {
  margin-left: 15px;
  border: none;
  background-color: transparent;
  display: none;
}

@include breakpoint-down(998px) {
  .header-nav__ww {
    position: fixed;
    top: 0;
    margin: auto;
    bottom: 0;
    left: -100%;
    width: 0;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav {
    transition: all ease 0.5s;
    position: absolute;
    left: 0;
    width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $light-color;
  }

  .header-nav__ww.opened {
    transition: all ease 0.4s;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .header-nav__ww.opened .nav {
    width: 35%;
  }

  .nav__item {
    margin-bottom: 20px;
  }

  .menu-open{
    display: block;
  }
}

@include breakpoint-down(670px) {
  .header-nav__ww.opened .nav {
    width: 55%;
  }
}

@include breakpoint-down(540px) {
  .header-nav__ww.opened .nav {
    width: 75%;
  }
}
