@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.page-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ebeff2;
}

.containerrr {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
}

.page-main {
  margin-top: 75px;
  padding: 25px 0;
  background-color: #ebeff2;
}

.page-main__wrapper {
  width: 100%;
  display: flex;
}

.sidebar-bar {
  width: 20% !important;
  min-width: 20% !important;
}

.outlet {
  width: 80% !important;
  min-width: 80% !important;
  border-radius: 15px;
}

.outlet-container {
  margin: 0 auto;
  padding-left: 20px;
}

@include breakpoint-down(998px) {
  .page-main__wrapper {
    flex-direction: column-reverse;
  }

  .outlet-container {
    padding: 0;
  }

  .sidebar-bar {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 0;
    margin: 0;
    width: auto !important;
    border-radius: 12px;
    background-color: #fff;
    z-index: 999;
  }

  .outlet {
    width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 80px;
  }

  .sidebar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
  }

  .sidebar-entrance {
    order: 3;
  }

  .sidebar-main,
  .sidebar-main__list {
    display: flex;
    align-items: center;
  }
}
