@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.outline-primary {
  border: 1px solid #007bff;
  color: #007bff;
}

.outline-info {
  border: 1px solid #17a2b8;
  color: #17a2b8;
}
.outline-secondary {
  border: 1px solid #6c757d;
  color: #6c757d;
}
.outline-success {
  border: 1px solid #28a745;
  color: #28a745;
}
.outline-danger {
  border: 1px solid #dc3545;
  color: #dc3545;
}
.outline-warning {
  border: 1px solid #ffc107;
  color: #ffc107;
}
.outline-dark {
  border: 1px solid #343a40;
  color: #343a40;
}
.btn-status {
  // width: 80% !important;
  margin-right: 5px;
  padding: 5px 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
  border-radius: 12px !important;
}
.text-field{
text-transform: none !important;
}