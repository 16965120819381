@import "../../../sass/variables.scss";

.landing-faq {
  margin-top: 85px;
  padding: 80px 0;
}

.landing-faq__title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: $blue-color;
  text-transform: uppercase;
  text-align: center;
}
