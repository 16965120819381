@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.main-header {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 12px;
}

.header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-inner__title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.header-inner__sel {
  background-color: transparent;
  color: $blue-color;
  border: none;
  outline: none;
}

.header-inner__btn {
  margin-left: 20px;
  padding: 5px 8px;
  background-color: $blue-color;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
