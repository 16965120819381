@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.formm-holder {
  position: relative;
  width: 100%;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    content: "";
    width: 1px;
    height: 100%;
    background-color: #e9e9e9;
  }
}
.inpp-countryy:focus{
  border-bottom: 2px solid $blue-color !important;

}
.inpp-countryy {
  padding: 7px 10px !important;
  font-size: 12px !important;
  border-bottom: 1px solid #e8e8e8 !important;

  &::placeholder {
    font-size: 12px;
    color: $light-grey-color;
  }

  &::selection {
    border-bottom: 2px solid $blue-color !important;
  }

  &.invalid {
    border: 1px solid red !important;
  }
}
.formm-holder__container {
  padding: 20px 0;
  width: 50%;
  min-height: 100%;
  // border-right: 1px solid #e9e9e9;

  &:last-child {
    padding: 20px;
    border: none;
  }
}

.frm-con {
  background-color: white;
  padding: 20px;
  width: 49%;
}
.form-body-wrapp {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.text-field {
  padding-bottom: 10px !important;
}

.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #e8e8e8 !important;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: $grey-color !important;
  font-size: 0.9rem !important;
}
.ant-select-selector {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  letter-spacing: 0.00938em !important;
  color: $grey-color !important;
}
// .MuiButtonBase-root:hover {
//   background-color: #109cf1;
//   color: #fff;
// }
.new_create {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
.css-1ctudgt-MuiButtonBase-root-MuiMenuItem-root.MuiButtonBase-root:hover {
  background-color: #109cf1 !important;
  color: #fff !important;
}
.MuiButtonBase-root-MuiMenuItem-root.MuiButtonBase-root:hover {
  background-color: #109cf1 !important;
  color: #fff !important;
}
.MuiMenuItem-root.MuiButtonBase-root:hover {
  background-color: #109cf1 !important;
  color: #fff !important;
}
.check-lable {
  display: flex;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  line-height: 1.4375em !important;
  letter-spacing: 0.00938em !important;
  color: $grey-color !important;
}
.icon-inf {
  align-items: center;
  margin-left: 10px;
  &:hover {
    color: #109cf1;
  }
}
.spn-inf {
  display: none;
  background-color: #fff;
}
.date-form {
  margin-top: 20px;
  // width: 100px;
}
.date-icon-form {
  font-size: 20px;
}

.anticon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
}

.ant-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
  font-size: 12px !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  color: $blue-color !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: $grey-color !important;
  font-size: 14px !important;
}

.button-edit-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-edit-title {
  padding-bottom: 10px;
  font-size: 20px;
  color: $blue-color;
}

.app-edit-title-wrapper {
  margin-bottom: 25px;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 14px !important;
  color: $light-grey-color;
}

.tt {
  margin-left: 20px !important;
}

.titleee {
  font-size: 12px;
  color: $blue-color;
  text-transform: capitalize;
}
.MuiNativeSelect-select.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}
.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}
.status-lable {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  margin-bottom: 6px !important;
  font-size: 12px !important;
  margin-right: 40px;
}
.succ-btn {
  margin: 10px 0px;
  justify-content: center;
  text-align: center;

  width: 50%;
}
.line-btn {
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 10px 0px;
}
.status-filed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
