@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.wrapper {
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}
.log-btn {
  padding: 0px 15px;
}

.invalid_feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.forms_login {
  margin-top: 85px;
  padding: 25px;
  background-color: #fff;
  border: 2px solid $blue-color;
  border-radius: 20px;
  width: 500px;
}
.title {
  display: flex;
  font-size: 25px;
  font-weight: 500;
  padding: 25px;
  align-items: center;
  justify-content: center;
}
.titless {
  width: 100%;
  padding: 0;
  text-align: center;
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: 500;
  color: $blue-color;
}
.link_forgot {
  margin-top: 10px;
  color: $blue-color;
  font-size: 10px;
  text-decoration: none;
}
.link_forgotr {
  text-align: right;
  margin-top: 10px;
  color: $blue-color;
  font-size: 10px;
  text-decoration: none;
}
.action-log-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-wr {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
}

.login-bt {
  border: none;
  background-color: $blue-color;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
}

.login-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@include breakpoint-down(640px) {
  .forms_login {
    padding: 20px 15px;
    width: 100%;
  }

  .titless {
    font-size: 20px;
  }

  .btnnrr {
    margin-top: 25px;
  }
}
