@import "../../../sass/variables.scss";

.landing-reg {
  margin-top: 85px;
  padding: 80px 0;
}

.landing-reg__title {
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 600;
  color: $blue-color;
  text-transform: uppercase;
  text-align: center;
}

.landing-reg__box{
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 15px;
  margin-bottom: 15px;
}

.landing-reg__right{
  margin-left: 20px;
}

.landing-reg__right-title{
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700px;
  color: $blue-color;
  text-transform: uppercase;

  &:hover{
    opacity: .8;
    text-decoration: none;
  }

  &::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.landing-reg__bottom{
  display: flex;
  align-items: center;
}

.landing-reg__info{
  color: $grey-color;
  font-size: 16px;
}

.landing-reg__info{
  margin-right: 20px;

  &:last-child{
    margin-right: 0;
  }
}

.reg-download {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 6px;
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(../../../assets/icon/download.svg);
    background-size: contain;
  }
}

.reg-calendar {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 6px;
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(../../../assets/icon/calendar.svg);
    background-size: contain;
  }
}

