@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.card {
  border-radius: 12px !important;
  border: none !important;
}

.app-tt {
  padding: 20px !important;
}

.app-heading {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: $blue-color;
}

.chat-wrapp {
  position: relative;
  width: 100%;
  height: 90vh;

  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chat-title {
  display: block;
  width: 50%;
  height: 50%;
  position: absolute;
  inset: 50% 0 0;
  margin: 0 auto;
  text-align: center;
  transform: translateY(-50%);
}

.chat-input {
  padding: 15px 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  z-index: 1000;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.anticon {
  margin: 0 !important;
}

.mess-inp {
  color: $grey-color !important;
  font-size: 13px !important;

  &::placeholder {
    color: $grey-color !important;
  }
}

.av {
  width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
  border-radius: 50%;
}
.list-deadline {
  overflow-y: auto;
  min-height: 350px;
  height: 350px;
  @include list-unstyled;

  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue-color;
    border: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $light-grey-color;
  }

  &::-webkit-scrollbar-thumb:active {
    background: $grey-color;
  }

  &::-webkit-scrollbar-track {
    background: $light-color;
    border: none;
  }

  &::-webkit-scrollbar-track:active {
    background: $light-color;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.icon-chat {
  width: 50px;
  align-items: center;
}
.chats-messages-i {
  display: flex;
  overflow-y: hidden;
  justify-content: flex-end;
}
.chats-messages-you {
  overflow-y: hidden;
  justify-content: flex-start;
}
.chat-both {
  justify-content: space-between;
  width: 100%;
}

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}
.date-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-message-left {
  margin-right: auto;
  width: 30%;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.ant-form-item {
  margin: 0 !important;
}

.position-relative {
  overflow-y: visible;
}

.chat-rightt {
  width: 50%;
}

@include breakpoint-down(580px) {
  .chat-rightt {
    padding-left: 20px;
    display: inline-block;
    width: 100%;
    max-width: 100% !important;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
}
