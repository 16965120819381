@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

// stylelar shu yerda boshlanadi

.mod-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mod-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mod-file-title,
.mod-file-name {
  margin: 0;
  font-size: 14px;
  color: $grey-color;
}

.mod-icon {
  margin-right: 10px !important;
}

.mod-inner-holder {
  display: flex;
  align-items: center;
  width: 90%;
}

.mod-inner-icon {
  width: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

// default stylelar to'g'irlangan joy

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  display: block !important;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  width: 100% !important;
  min-width: 100% !important;
  display: inline-block !important;
}

.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-modal-title {
  margin-right: 25px;
}

.ant-modal-content {
  border-radius: 12px !important;
}

.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
