@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.res-wrapper {
  padding: 60px 0;
  margin-top: 85px;
}

.res-wrapper-inner {
  border-radius: 15px !important;
}

.tttt {
  padding: 10px;
}

.wrrr {
  padding: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.rester__name-row {
  display: flex;
}

.rester-name {
  min-width: 30%;
  color: $blue-color;
  font-size: 14px;
  font-weight: 600;
}

.rester-title {
  font-size: 12px;
  color: $grey-color;
  min-width: 70%;
}

.head-name-rester{
  border-bottom: 1px solid #e9e9e9;
  width: 40%;
  margin:0px 0px 15px 0px;
}

// .css-1ex1afd-MuiTableCell-root {
//   border: none !important;
//   color: $grey-color !important;

//   &:last-child {
//     border: none !important;
//   }
// }

// .css-1q1u3t4-MuiTableRow-root {
//   border-bottom: 1px solid #e9e9e9;
//   cursor: pointer;

//   &:last-child {
//     border: none !important;
//   }
// }

// .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
//   box-shadow: none !important;
//   border: 1px solid #e9e9e9 !important;
//   border-radius: 15px !important;
// }

// .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
//   border-radius: 15px !important;
//   box-shadow: none !important;
//   border: 1px solid $blue-color;
// }

// .css-1azl6jz-MuiTableCell-root,
// .css-177gid-MuiTableCell-root {
//   text-align: center !important;
//   min-width: 14% !important;
//   width: 14% !important;
// }

// .css-1ex1afd-MuiTableCell-root,
// .css-1ygcj2i-MuiTableCell-root {
//   min-width: 28% !important;
//   width: 28% !important;
//   text-align: left !important;
// }
