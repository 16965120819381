@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1111;
}

.modal-gs {
  width: 500px;
  background-color: #fff;
}

.modal-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  border-bottom: 1px solid #e7e7e7;
}

.modal-body {
  padding: 15px;
}

.modal-head__title {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
}

.modal-head__btn {
  background-image: url(../../../assets/icon/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  width: 15px;
  height: 15px;
  border: none;
  cursor: pointer;
}

.modal-form,
.modal-form__holder {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-form__holder {
  margin-bottom: 20px;
}

.modal-form__holder-label {
  margin-bottom: 8px;
  color: $blue-color;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.modal-from__holder-sel {
  width: 100%;
  padding: 10px 0;
  padding-bottom: 10px;
  background-color: transparent;
  color: $icon-color;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  outline: none;
  text-transform: capitalize;
}

.modal-form__holder-inp {
  padding: 10px 0;
  width: 100%;
  background-color: transparent;
  color: $grey-color;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  outline: none;

  &::placeholder {
    color: $icon-color;
  }
}

.modal-form__button {
  margin-left: auto;
}

.modal-form__btn {
  padding: 10px 25px;
  background-color: $blue-color;
  border: none;
  border-radius: 12px;
  color: $light-color;
  cursor: pointer;
}
.modalmain {
  width: 300px;
  flex-direction: column;
  padding: 0px, 20px;
}
.modalmain__text {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: $text-color;

}
.modalmain__text_name {
  font-style: italic;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: $text-color;
  opacity: 0.5;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.modalmain__btn {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}