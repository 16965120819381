@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

//* page container style
.page-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.iframe__holder {
  // opacity: 0.6;
  // margin-top: 15px;
}

.page-content.opened .landing-listt__holder {
  transform: translateX(0%);
  opacity: 1;
}

.page-content.opened .lists__wrapperr-hd {
  width: 100%;
  height: 100vh;
}

.landing-container {
  max-width: 1240px;
  width: 100%;
  padding: 0 20px !important;
  margin: 0 auto !important;
}

//* landing main style
.landing-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.landing-wrapper {
  margin-top: 85px;
  padding: 80px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/icon/hero-bgi-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.landing-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 500;
  padding: 20px 0;
  background-color: $darkk-blue-color;
}

//* landing header

.landing-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-logo__tt,
.landing-footer__holder-text {
  margin: 0;
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
}

.landing-header__link,
.logo-holder {
  display: flex;
  align-items: center;
}

.landing-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.landing-list__item {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.landing-list__link {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transition: color 0.4s ease;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $blue-color;
  }
}

.landing-list__link_active {
  text-decoration: none;
  color: $blue-color;
  font-size: 16px;
  font-weight: 400;
  transition: color 0.4s ease;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
    color: $blue-color;
  }
}

.landing-header__btns {
  display: flex;
  align-items: center;
}

.landing-header__btn {
  padding: 3px 15px;
  margin-right: 10px;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 12px;
  text-transform: capitalize;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
}

.signup {
  border: 2px solid $blue-color;
  transition: all 0.4s ease;
  color: $blue-color;

  &:hover {
    border: 2px solid transparent;
    background-color: $blue-color;
    color: #fff;
  }
}

.signin {
  background-color: $blue-color;
  border: 2px solid transparent;
  color: #fff;

  &:hover {
    border: 2px solid $blue-color;
    background-color: transparent;
    color: $blue-color;
  }
}

.btn-holderr {
  display: flex;
  align-items: center;
}

.menu-btn {
  margin-left: 10px;
  background-image: url(../../assets/icon/menu.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  width: 30px;
  height: 30px;
  border: none;
  display: none;
}

.landing-btn__icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

//* landing footer

.landing-footer__holder-text {
  font-size: 24px;
}

.landing-footer__holder {
  margin-top: 15px;
}

.ttt {
  margin-top: 20px;
  color: #fff;
  width: 300px;
}

//* hero-style
.landing-hero {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.landing-hero__left {
  text-align: left;
  width: 40%;
}

.landing-hero__right {
  width: 60%;
}

.landing-hero__title {
  margin-bottom: 20px;
  font-size: 54px;
  font-weight: 600;
  color: $blue-color;
}

.landing-hero__text {
  margin-bottom: 20px;
  width: 100%;
  color: $dark-blue-color;
  line-height: 26px;
  font-size: 18px;
  font-weight: 400;
}

//* landing-footer
.landing-footer {
  margin-top: auto;
  padding: 25px 0;
  background-color: $darkk-blue-color;
}

.landing-footer__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.landing-footer__holder-title {
  padding: 10px 0;
  color: $blue-color;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}

.landing-footer__holder-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.socials {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.socials__item {
  margin-right: 12px;

  &:last-child {
    margin: 0;
  }
}

.landing-footer__holder-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
}

.landing-footer__holder-img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.landing-footer__holder-day {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 700;
}

.socials__item {
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }
}

.socials__link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;

  &:hover {
    color: $blue-color;
    text-decoration: none;
  }
}

.socials__tt {
  margin: 0;
  margin-left: 10px;
  text-transform: capitalize;
}

.landing-footer__holder-desc {
  margin: 0;
  margin-left: 12px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.phone-link:hover {
  color: $blue-color;
  text-decoration: underline;
}

.landing-footer__holder-link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    color: $blue-color;
    text-decoration: none;
  }
}

.user-button {
  background-color: transparent;
  border: none;
}

//* landing-about
.landing-about,
.landing-contact {
  padding: 50px 0;
  text-align: center;
  background-color: $dark-blue-color;
}

.landing-about__wrapper {
  display: flex;
  align-items: center;
}

.landing-about__left {
  text-align: left;
  width: 60%;
}

.landing-about__right {
  width: 40%;
}

.landing__heading {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: $blue-color;
  // text-transform: capitalize;
}

.landing__desc {
  margin: 0 auto 40px;
  width: 800px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.landing-about__title {
  margin-bottom: 24px;
  color: $blue-color;
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
}

.landing-about__text {
  color: #fff;
  width: 600px;
  white-space: pre-wrap;
}

// landing-contact

.landing-blog {
  padding: 50px 0;
  text-align: center;
  background-image: url(../../assets/icon/hero-bgi-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.landing__description {
  margin: 0 auto 40px;
  width: 800px;
  font-size: 16px;
  font-weight: 400;
  color: $dark-blue-color;
}

//* landing blog
.landing-blog__wrapper {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-blog {
  width: calc(100% - 12px);
  background-color: #fff;
  border-radius: 12px !important;

  &:last-child {
    margin: 0 !important;
  }
}

.box-blog__body {
}

.box-blog__head {
  width: 100%;
  height: 240px;
  background-color: #e9e9e99d;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.box-blog__link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.box-blog__img {
  width: 100%;
  height: 240px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.box-blog__body {
  padding: 20px 10px;
}

.box-blog__title {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 700;
  color: $blue-color;
  text-transform: uppercase;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.box-blog__text {
  width: 100%;
  margin: 0;
  font-size: 15px;
  color: $dark-blue-color;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

// landing contact
.landing-contact__holder {
  padding: 30px;
  display: inline-block;
  border: 2px solid $icon-color;
  border-radius: 12px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-form__holder {
  width: 500px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    margin: 0;
  }
}

.btn-holder {
  align-items: flex-end;
}

.contact-form__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  color: $blue-color;
  text-transform: capitalize;
}

.contact-form__inp,
.contact-form__textarea {
  padding: 12px 8px;
  width: 100%;
  outline: none;
  border: 1px solid $blue-color;
  color: $grey-color;
  font-size: 14px;
  resize: none;
  border-radius: 8px;

  &::placeholder {
    color: $light-grey-color;
  }
}

.contact-form__btn {
  padding: 8px 12px;
  background-color: $blue-color;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.btn-wrapperr {
  display: flex;
  align-items: center;
}

//* slick style
.slick-slider.slick-initialized {
  width: 100%;
}

.slick-dots li button::before {
  margin-top: 15px;
  color: $blue-color !important;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  display: none !important;
}

//* sel-lang

.sel-lang {
  display: none;
}

//! mobile style starts here
@include breakpoint-down(1140px) {
  .landing-list__link,
  .landing-list__link_active {
    font-size: 12px;
  }
}

@include breakpoint-down(992px) {
  .lists__wrapperr-hd {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      top: 20px;
      background-image: url(../../assets/icon/close.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  .menu-btn {
    display: block;
  }

  .landing-list__link,
  .landing-list__link_active {
    font-size: 14px;
  }

  .landing-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .landing-listt__holder {
    text-align: left;
    padding: 50px 25px;
    max-width: 280px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: $dark-blue-color;
    transform: translateX(-100%);
    transition: all 0.4s ease;
  }

  .sel-lang {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
  }

  .sel-lang__btn {
    padding: 4px 12px;
    border: none;
    background-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 20px;
  }

  .sel-lang__btn.sel-lang__btn--active {
    border: 1px solid $blue-color;
  }

  .lang-select {
    display: none;
  }

  .landing-list__item {
    margin: 0;
    margin-bottom: 30px;
  }

  .landing-footer__wrapper {
    flex-wrap: wrap;
  }

  .landing-footer__holder-links {
    display: none;
  }

  .landing-footer__holder-desc {
    width: 100%;
  }

  .socials {
    margin-top: 20px;
    width: 100%;
    flex-direction: row;
  }

  .socials__tt {
    display: none;
  }

  .landing-about__text {
    width: 100%;
  }
}

@include breakpoint-down(850px) {
  .landing__desc,
  .landing__description {
    width: 100%;
    font-size: 14px;
  }

  .landing-list__link,
  .landing-list__link_active {
    font-size: 14px;
  }
}

@include breakpoint-down(640px) {
  .lists__wrapperr-hd::after {
    width: 30px;
    height: 30px;
  }

  .landing-listt__holder {
    width: 50%;
  }

  .landing-wrapper {
    padding: 40px 0;
  }

  .landing-hero__title,
  .landing__heading {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .landing-hero,
  .landing-about__wrapper {
    flex-direction: column-reverse;
  }

  .landing-about__right,
  .landing-hero__right {
    margin-bottom: 25px;
    text-align: center;
  }

  .landing-contact__holder,
  .landing-about__left,
  .landing-hero__left {
    width: 100%;
    text-align: center;
  }
  .contact-form__holder {
    width: 100%;
  }

  .landing-header__btn {
    padding: 5px 8px;
    font-size: 10px;
  }

  .menu-btn {
    width: 25px;
    height: 25px;
    margin-left: 6px;
  }

  .landing-logo__tt {
    font-size: 12px;
  }

  .landing-footer__holder-text {
    font-size: 12px;
  }

  .landing-footer__holder-icon {
    width: 35px;
    height: 35px;
    object-fit: contain;
  }

  .ttt,
  .landing-hero__text,
  .landing-about__text {
    font-size: 14px;
  }

  ::placeholder,
  .contact-form__inp,
  .contact-form__btn,
  .box-blog__text {
    font-size: 12px;
  }

  .box-blog__title {
    font-size: 14px;
  }
}

@include breakpoint-down(540px) {
  .page-content.opened .landing-listt__holder {
    max-width: 240px;
  }

  .landing-footer__wrapper {
    flex-direction: column;
  }

  .modalmain {
    width: 100%;
  }

  .landing-container {
    padding: 0 12px;
  }

  .sel-lang__btn {
    font-size: 12px;
  }

  .landing-contact__holder {
    padding: 15px;
  }
}

@include breakpoint-down(420px) {
  // .landing-footer {
  //   text-align: center;
  // }

  // .landing-footer__wrapper {
  //   justify-content: center;
  //   align-items: center;
  // }

  .socials {
    justify-content: center;
  }

  .ttt {
    width: 100%;
  }

  .landing-footer__holder-list {
    text-align: left;
  }

  .landing-contact__holder {
    padding: 15px;
  }

  .landing-socials__holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include breakpoint-down(480px) {
  .landing-listt__holder {
    width: 60%;
  }

  .landing-list__link,
  .landing-list__link_active {
    font-size: 12px;
  }
}
