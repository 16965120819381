@import "./variables.scss";
@import "./mixins.scss";

html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.root {
  flex-grow: 1;
}

body {
  font-weight: 400;
  font-size: 14px;
  color: $text-color;
  background-color: #ebeff2;
  padding: 0;
  margin: 0;
  font-family: $main-font;
  line-height: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}
.tox.tox-tinymce {
  height: 300px !important;
}
.tox-statusbar__branding {
  display: none !important;
}
.ck-editor__editable {
  height: 200px;
}
