@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

//head style
.emp-head {
  @include list-unstyled;
  padding: 25px 0 25px 20px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}

td,
th {
  text-align: center !important;
}

tr {
  display: flex;
  align-items: center;
}

.tttt {
  border-radius: 12px !important;
  overflow: auto;
}

.pagin {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
}

.ant-pagination-item {
  border-radius: 10px !important;
  color: #707683 !important;
  // color: $blue-color !important;
}

.ant-pagination-item-active {
  color: $blue-color !important;
}

.ant-pagination-next,
.ant-pagination-item-link {
  border-radius: 10px !important;
}

.thh {
  font-size: 14px !important;
  color: $blue-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  min-width: 11% !important;
  width: 11% !important;

  &:first-child {
    min-width: 4% !important;
  }

  &:last-child {
    min-width: 8% !important;
  }
}

.out {
  width: 100% !important;
  margin-right: 5px;
  padding: 5px 2px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px !important;
  border-radius: 12px !important;
  color: $grey-color;
}

.tdd {
  min-width: 11% !important;
  width: 11% !important;
  min-height: 75px !important;
  font-size: 12px !important;
  color: $grey-color;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &:first-child {
    color: $grey-color;
    min-width: 4% !important;
    max-width: 4% !important;
  }

  &:last-child {
    min-width: 8% !important;
  }
}

.tdd-app {
  margin: 0;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.tdd-date,
.thh-date {
  min-width: 8% !important;
  max-width: 8% !important;
}

.tdd-btn,
.thh-btn {
  min-width: 14% !important;
  max-width: 14% !important;
}

.tss {
  margin: 0;
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.resp {
  width: 100% !important;
  background-color: #fff;
  border-radius: 12px;
  &::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e9e9e9;
    border-radius: 12px;
    border: none;
  }
}

.emp-img {
  margin-right: 0 !important;
}

.emp-head__enter {
  min-width: 22% !important;
  max-width: 22% !important;
  width: 22% !important;
  display: flex;
  align-items: center;
  text-align: center;
}

.emp-head__hashtag {
  padding: 2px 0;
  font-size: 14px !important;
  font-weight: 400 !important;
  min-width: 22px;
  color: #e7e7e7 !important;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.emp-head__num {
  min-width: 24px;
}

.btn-ic {
  font-size: 22px;
}

.btn-eye {
  color: $green-color;
}

.btn-edit {
  color: $blue-color;
}

.btn-trash {
  color: $red-color;
}

.emp-head__text {
  text-align: center;
}

.emp-head__textt {
  text-align: center;
}

.emp-head__holder {
  padding: 0 10px;
  text-align: center;
  min-width: 13% !important;
  width: 13% !important;
}

.emp-list {
  width: 100%;
  @include list-unstyled;
}

.item-emp {
  padding: 20px 0 20px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
}

.item-emp__person {
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 22% !important;
  width: 22% !important;
}

.item-emp__holder {
  min-width: 13% !important;
  width: 13% !important;
  text-align: center;
  padding: 0 10px;
}

.emp-head__textt,
.emp-head__text {
  font-size: 15px;
  font-weight: 500;
  color: $grey-color;
}

.emp-num {
  margin-right: 0 !important;
}

.divv {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 82px;
  min-width: 82px;
}

.person-info__name {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: $text-color;
}
.emp-num {
  margin-right: 0 !important;
}

.item-emp__text {
  color: $grey-color;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.person-info__num {
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  margin-right: 24px;
  color: $blue-color;
  border: 1px solid $blue-color;
  border-radius: 4px;
  text-align: center;
}

.item-emp__action-btn {
  margin-right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.btn-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.modalmain {
  width: 100%;
  flex-direction: column;
  padding: 0px, 20px;
}

.modalmain__text {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: $text-color;
}

.modalmain__text_name {
  font-style: italic;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: $text-color;
  opacity: 0.5;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modalmain__btn {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.pagination {
  @include list-unstyled;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  background-color: white;
}

.pagination__item:not(:last-child) {
  margin-right: 10px;
}

.pagination__link {
  display: inline-block;
  margin-top: 60px;
  padding: 5px 12px;
  background-color: #fafafa;
  font-weight: 500;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
  color: #424242;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background-color: rgba(34, 158, 217, 0.9);
    color: #fff;
  }
}

.pagination__link--active {
  background-color: $blue-color;
  color: #fff;
}

.Mui-selected {
  color: #fff !important;
  background-color: $blue-color !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 80% !important;
  max-width: 550px !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  padding: 40px 20px !important;
}

.statuss {
  cursor: default !important;
  padding: 0px 10px !important;
}

.btnnn {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@include breakpoint-down(998px) {
  .tddd {
    min-height: 110px;
  }
}

@include breakpoint-down(680px) {
  .out {
    font-size: 10px !important;
    min-width: auto;
  }
}
