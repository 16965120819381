@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.add-application {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 12px !important;
}
.ant-alert-content {
  margin-left: 5px !important;
}
.ant-alert {
  border-radius: 15px !important;
}
.xran-wrapp {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.app-show {
  width: 50%;
}
.xron-show {
  background-color: white;
  padding: 20px;
  width: 48.8%;
}
.form-body {
  background-color: transparent;
  width: 100%;
}

.ant-steps-item-content,
.ant-steps-item-icon {
  display: inline-block;
  vertical-align: inherit !important;
}
.ant-steps-item-title {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: $main-font !important;
}
.btn-gr {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
}

.frm-con {
  border-radius: 12px !important;
}

.btn-pr-form {
  margin-right: 12px;
}

.btn-next-form {
  // margin-top: 15px;
  align-content: flex-end;
  // display: flex;
  justify-content: flex-end;
}

.tabb {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-ink-bar {
  background-color: transparent;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
  border: none !important;
}
.ant-tabs-tab {
  margin: 0 !important;
  padding: 5px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
  font-size: 12px !important;
}

.ant-tabs-tab-active {
  background-color: #f6f6f6;
}

.anticon {
  margin: 0 !important;
  margin-bottom: 6px !important;
}

.app-title {
  width: 100%;
  padding: 10px 0 20px;
  font-size: 18px;
  margin-bottom: 20px;
  color: $blue-color;
  border-bottom: 2px solid #f6f6f6;
}

.css-zvqyfp-MuiFormControl-root-MuiTextField-root
  .MuiInputLabel-formControl.Mui-disabled {
  color: $light-grey-color !important;
  -webkit-text-fill-color: $light-grey-color !important;
  margin-bottom: 6px !important;
  font-size: 14px !important;
}

.css-zvqyfp-MuiFormControl-root-MuiTextField-root
  .MuiInputBase-input.Mui-disabled {
  color: $grey-color !important;
  -webkit-text-fill-color: $grey-color !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.ant-steps-vertical {
  display: flex;
  flex-direction: column !important;
}

@include breakpoint-down(860px) {
  .xran-wrapp {
    flex-direction: column;
  }

  .xron-show {
    width: 100%;
  }

  .app-show {
    margin-bottom: 20px;
    width: 100%;
  }
}

@include breakpoint-down(575px) {
  .ant-steps-vertical {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }

  .ant-steps-item-container,
  .ant-steps-item-content {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
.ant-steps-item-container {
  outline: none;
  display: flex !important;
  align-items: center !important;
}
