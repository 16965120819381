@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.head-pay {
  display: flex;
  justify-content: space-between;
}
.head-pay-contract {
  display: flex;
  flex-direction: column;
}
.btn-outline-warning:hover {
  color: #fff !important;
}
.out {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.pay-list {
  margin-top: 20px;
}
.add-application {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.form-body {
  background-color: transparent;
  width: 100%;
}
.cards {
  width: 50%;
}
.cards-body {
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  height: 50vh;
}

.pay-wrapper__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 58px;
  padding: 0 24px;
  border-bottom: 2px solid #f6f6f6;
}

.pay-wrapper__info {
  @include list-unstyled;
  display: flex;
  flex-direction: column;
}

.pay-wrapper__item {
  display: flex;
  align-items: center;
}

.pay-wrapper__item-tt {
  margin-bottom: 0;
  display: inline-block;
  color: $light-grey-color;
  font-size: 10px;
  margin: 0;
  text-transform: capitalize;
}

.payed {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 6px;
    content: "";
    width: 4px;
    height: 4px;
    background-color: $green-color;
    border-radius: 50%;
  }
}

.not-payed {
  display: flex;
  align-items: center;

  &::before {
    margin-right: 6px;
    content: "";
    width: 4px;
    height: 4px;
    background-color: $yellow-color;
    border-radius: 50%;
  }
}

.pay-wrapper__body {
  max-height: 93px;
  min-height: 93px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px 24px;

  &::-webkit-scrollbar {
    width: 3px;
    max-height: 4px !important;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue-color;
    border-radius: 50px;
    border: none;
  }
}

.payy-body {
  padding: 6px 15px;
}

.pay-table__head-tt {
  margin: 0;
  font-size: 18px;
  color: $blue-color;
  text-transform: capitalize;
}

.pay-table__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
}

.pay-wrapper__num {
  padding: 0;
  margin: 0;
  display: inline-block;
  color: $grey-color;
  font-size: 12px;
}

.pay-wrapper__holder {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pay-wrapper__tt {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
  color: $blue-color;
  text-transform: capitalize;
}

.pay-wrapper__dot {
  margin-right: 15px;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.pay-wrapper__dot.pay {
  background-color: $green-color;
}

.pay-wrapper__dot.not {
  background-color: $yellow-color;
}

.pay-wrapper__status {
  display: flex;
  align-items: center;
}

.pay-wrapper__status::before {
  width: 5px;
  height: 5px;
  background-repeat: red;
  content: "";
}

.drug-name {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: $grey-color;
  text-transform: capitalize;
  font-size: 14px;
}

.pp-td,
.pp-th {
  min-width: 25%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pp-bb {
  padding: 10px 15px;
}

.pp-tr {
  min-height: 58px;
}

.pp-th {
  font-size: 12px !important;
  color: $blue-color;
  border: none !important;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  overflow: hidden;
  display: block;
}

.pp-td {
  padding: 30px 8px !important;
}

.btnnn:last-child {
  margin: 0 !important;
}

.pay-wrapper {
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pay-wrapper__table {
  min-width: calc(40% - 10px);
  max-width: calc(40% - 10px) !important;
}

.pay-wrapper__id {
  min-height: 116px;
  background-color: #fff;
  border-radius: 15px;
  min-width: calc(28% - 10px);
  max-width: calc(28% - 10px) !important;
}

.pay-wrapper__infoo {
  min-width: calc(32% - 10px);
  max-width: calc(32% - 10px) !important;
}

.pay-thh {
  font-size: 14px !important;
  color: $blue-color;
  border: none !important;
  min-width: 16% !important;
  width: 16%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    min-width: 4% !important;
    width: 4%;
  }
}

.pay-tdd {
  font-size: 13px;
  color: $grey-color;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-width: 16%;
  min-height: 65px;
  width: 16%;

  &:first-child {
    min-width: 4%;
    width: 4%;
  }
}

//list
.pay-wrapper__list {
  @include list-unstyled;
  display: flex;
  flex-direction: column;
}

.pay-wrapper__item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.pay-wrapper__title {
  width: 30%;
  min-width: 30%;
  text-transform: capitalize;
  font-size: 14px;
  color: $blue-color;
}

.pay-wrapper__name,
.pay-wrapper__status {
  margin: 0;
  color: $grey-color;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.pay-wrapper__name {
  width: 70%;
  min-width: 70%;
}

.pay-wrapper__status.pay::before {
  width: 5px;
  height: 5px;
  background-color: $green-color;
  border-radius: 50%;
  margin-right: 5px;
}

.pay-wrapper__status.not::before {
  width: 5px;
  height: 5px;
  background-color: $yellow-color;
  border-radius: 50%;
  margin-right: 5px;
}

@include breakpoint-down(1240px) {
  .pay-wrapper__table,
  .pay-wrapper__id {
    max-width: auto;
  }
}

@include breakpoint-down(1180px) {
  .pay-wrapper {
    flex-direction: column;
  }

  .pay-wrapper__table,
  .pay-wrapper__id {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
