@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.wrapper {
  background-color: $white-color;
}
.flex-wrap{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-pr-form{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
}
.ant-collapse {
  border-radius: 12px !important;
  border: none !important;
}

.ant-collapse-extra {
  color: $blue-color;
  margin-right: 10px;
  font-size: 16px;
}
.ant-list-item-meta-avatar {
  color: $blue-color;
  font-size: 20px;
}
.add-application {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.form-body {
  background-color: transparent;
  width: 100%;
}
.ant-list-item-meta-content {
  margin-bottom: 0px !important;
}
.ant-collapse-content .ant-collapse-content-active {
  padding: 1px 16px !important;
}
.ant-list-item {
  padding: 4px 0px !important;
}

.ant-collapse-arrow {
  padding: 5px;
  font-size: 12px !important;
  color: $grey-color !important;
}

.ff {
  font-size: 25px !important;
}

.ant-collapse-expand-icon {
  margin-right: 15px !important;
}

.ant-collapse-header-text{
  margin-right: 10px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@include breakpoint-down(540px) {
  .doc-btnn {
    padding: 3px;
    font-size: 12px !important;
  }
}
