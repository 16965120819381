@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.not-card {
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.ant-card-body {
  padding: 15px 30px !important;
}
.pagin {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
}
.ant-pagination-item {
  border-radius: 10px !important;
  color: #707683 !important;
  // color: $blue-color !important;
}
.ant-pagination-item-active {
  color: $blue-color !important;
}
.ant-pagination-next,
.ant-pagination-item-link {
  border-radius: 10px !important;
}
.pagination {
  @include list-unstyled;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  background-color: white;
}

.pagination__item:not(:last-child) {
  margin-right: 10px;
}

.pagination__link {
  display: inline-block;
  margin-top: 60px;
  padding: 5px 12px;
  background-color: #fafafa;
  font-weight: 500;
  font-size: 13px;
  line-height: 27px;
  text-align: center;
  color: #424242;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background-color: rgba(34, 158, 217, 0.9);
    color: #fff;
  }
}

.pagination__link--active {
  background-color: $blue-color;
  color: #fff;
}
.wrr {
  position: relative;

  &::before {
    position: absolute;
    top: 6px;
    left: -16px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $icon-color;
  }
}

.not-title {
  margin: 0;
  margin-bottom: 5px;
  font-size: 15px;
  color: $blue-color;
}

.date-wr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.not-desc {
  margin: 0;
  margin-bottom: 12px;
  font-size: 12px;
  color: $light-grey-color;
}

.not-date {
  display: flex;
  align-items: center;
  color: $grey-color;
  font-size: 10px;

  &::before {
    margin-right: 4px;
    content: "";
    background-image: url(../../assets/icon/calendar.svg);
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    background-size: contain;
  }
}

.not-text {
  margin: 0;
  color: $grey-color;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 20px;
}
