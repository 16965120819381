.not-found {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-image: url("../../assets/img/animate.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.not-found__btn {
  z-index: 10000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom:3%
}
