@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.notics {
  font-size: 14px;
}

.cricleactives {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-color;
  color: white;
  border-radius: 50%;
}
.cricleactive {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-color;
  color: white;
  border-radius: 50%;
}
.cricleinactive {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  color: white;
  border-radius: 50%;
}
.wrap-head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ant-timeline-item-label {
  padding: 0 20px !important;
}

.xron-show {
  border-radius: 12px;
}

.btnn-holderr {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.chr-title {
  color: $grey-color;
  font-size: 15px !important;
}

.chr-text {
  color: $light-grey-color;
  font-size: 12px;
}

.ant-timeline-item{
  padding: 25px 0 !important;
  margin-bottom: 15px !important;
}

.ant-timeline-item-content p{
  padding-right: 10px;
  margin-bottom: 0 !important;
  color: $light-grey-color !important;
  line-height: 18px;
}

.ant-timeline-item-left .ant-timeline-item-content{
  top: -42px;
  left: calc(50% - (-4px)) !important;
}

.ant-timeline-item-head{
  top: -8px;
}