@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

//settings input
.settings {
  background-color: #fff;
  border-radius: 12px;
  // height: 100%;
}
.inpps-select {
  padding: 4px 10px !important;
  font-size: 12px !important;
  border: 2px solid #e8e8e8 !important;
  border-radius: 12px !important;
  width: 100%;
}
.ant-form-item-labelss {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  color: #109cf1 !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 12px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px !important;
  font-size: 12px !important;
  color: $dark-blue-color;
}
.settings__info {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
  border-bottom: 1px solid $border-color;
}

.info-settings__logo {
  position: relative;
  margin-right: 20px;
}

.info-settings__img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.info-settings__form {
  width: 100%;
  column-count: 2;
  display: block;
}

.info-settings__img {
  margin-right: 75px;
  display: block;
}

.settings-form__holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.form-holder__label {
  margin-bottom: 20px;
}

.form-holder__input {
  width: 100%;
  padding: 12px 8px !important;
  border: 1px solid $icon-color;
  outline: $dark-blue-color;
  font-size: 14px;
  color: $dark-blue-color;

  &:focus {
    outline: $dark-blue-color;
    border: 1px solid $dark-blue-color;
  }

  &::placeholder {
    font-size: 12px;
    color: $light-grey-color;
  }
}

.info-settings__btn {
  position: absolute;
  bottom: 10px;
  right: 25px;
  background-color: $border-color;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
}

.info-settigns__icon {
  color: $dark-blue-color;
  font-size: 22px;
}

//location
.settings__location {
  padding: 40px;
}
.modal__images {
  position: relative;
  width: 180px;
  height: 180px;
  background-color: #e6e6e6;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__images-tt {
  display: flex;
  align-items: center;
  color: $light-grey-color;
  font-size: 12px;
  text-transform: uppercase;

  &::before {
    margin-right: 2px;
    background-image: url(../../assets/icon/image-add.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
    content: "";
  }
}
.modal-file__inp {
  display: none;
}

.ant-input {
  color: $grey-color !important;
  font-size: 12px !important;

  &::placeholder {
    text-transform: capitalize !important;
    color: $grey-color !important;
    font-size: 12px !important;
  }
}

.ant-input-affix-wrapper {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.inppp {
  border-radius: 12px !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-form-item-label > label {
  color: $blue-color !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-size: 12px !important;
}

.ant-checkbox-wrapper {
  color: $grey-color !important;
  font-size: 13px !important;
}

.modal__div {
  position: relative;
}

.edit-avatar {
  padding: 6px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  background-color: #f6f6f6;
  border-radius: 50%;
}

@include breakpoint-down(620px) {
  .settings__info {
    padding: 20px;
    flex-direction: column;
  }
}
