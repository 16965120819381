@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.card-opened {
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
}

.card-opened__title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 500;
  color: $blue-color;

  &::before {
    margin-right: 15px;
    background-color: $light-grey-color;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
  }
}
.card-opened__names{
  display: flex;
}
.card-opened__name {
  margin: 0;
  margin-bottom: 15px;
  display: block;
  color: $light-grey-color;
  font-size: 14px;
}
.card-opened__name-app {
  margin-left: 15px;
  margin-bottom: 15px;
  display: block;
  color: $light-grey-color;
  font-size: 14px;
}

.card-opened__desc {
  margin: 0;
  margin-bottom: 20px;
  color: $dark-blue-color;
  font-size: 15px;
  width: 100%;
}

.card-opened__date {
  color: $light-grey-color;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &::before {
    margin-right: 6px;
    background-image: url(../../assets/icon/calendar.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    content: "";
  }
}
