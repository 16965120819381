@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.bgd {
  width: 100%;
  background-color: #fff;
  border-radius: 12px !important;
}

.circ {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $icon-color;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
}

.bgd-head {
  padding: 15px 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.bgd-head__wr {
  display: flex;
  align-items: center;
}

.bgd-body {
  padding: 0 24px !important;
}

.bgd-head__title {
  margin: 0;
  margin-right: 4px;
  font-size: 14px;
  color: $blue-color;
  text-transform: capitalize;
}

.bgd-head__link {
  text-decoration: none;
  font-size: 12px;
  color: $dark-blue-color;
  transition: all ease 0.4s;

  &:hover {
    text-decoration: none;
    color: $blue-color;
  }
}

.bgd-body__list {
  @include list-unstyled;
}

.bgd-body__item {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;

  &:last-child {
    border: none;
  }
}

.bgd-name {
  margin: 0;
  font-size: 12px;
  color: $grey-color;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  white-space: nowrap;
  display: block;
  max-width: 150px;

  &:hover {
    text-decoration: none;
    color: $blue-color;
  }
}

.bgd-date {
  color: $light-grey-color;
  font-size: 10px;
}

@include breakpoint-down(998px) {
  .bgd {
    display: none;
  }
}
